<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="regionColor"
            v-model="regionMsj")
            | {{ regionText }}
            v-btn(flat dark @click="regionMsj = false") {{$t('cerrar')}}
        v-dialog(v-model="regionModal" max-width="600" persistent)
            v-form(v-if="regionModal")
                v-card
                    v-card-title
                        | {{$t(!updateRegion ? 'Crear' : 'Editar')}} {{ $t('Región') }}&nbsp;
                        span(v-if="type == t.STATE")
                            | {{$t('Estados')}} {{ !Array.isArray(info) && info.name.length ? ' - '+info.name : '' }}
                        span(v-else-if="type == t.CITY") 
                            | {{$t('Ciudades')}} {{ !Array.isArray(info) && info.name.length ? ' - '+info.name : '' }}
                        span(v-else-if="type == t.ZONE") 
                            | {{$t('Zonas')}} {{ !Array.isArray(info) && info.name.length ? ' - '+info.name : '' }}
                        span(v-else) {{$t('Países')}}
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12)
                                v-text-field(:label="$t('Nombre')" v-model="regionModel.name")
                            v-flex(xs12 v-if="type == t.ZONE && (regions.length ? updateRegion : true)")
                                v-text-field(:label="$t('División')" v-model="regionModel.division")
                            v-flex(xs12 v-if="![t.STATE, t.CITY, t.ZONE].includes(parseInt(type))")
                                v-text-field(:label="$t('Tel')" v-model="regionModel.tel")
                            v-flex(xs12 v-if="![t.STATE, t.CITY, t.ZONE].includes(parseInt(type))")
                                v-text-field(:label="$t('ISO (2)')" v-model="regionModel.iso")
                            v-flex(xs12)
                                label {{ $t('Habilitado') }}
                                v-divider.mt-2
                                .switch-group
                                    label {{ $t('No') }}
                                    v-switch(:label="$t('Si')" color="success" v-model="regionModel.status")
                                br
                        v-layout(row wrap)
                            h3 {{ $t('Ingrese las coordenadas de la región ó seleccione la ubicación en el mapa') }}
                            v-flex(xs12 sm6)
                                v-text-field(:label="$t('Latitud')" v-model="regionModel.lat")
                            v-flex(xs12 sm6)
                                v-text-field(:label="$t('Longitud')" v-model="regionModel.lng")
                            v-flex(xs12)
                                .btns.btns-right
                                    v-btn(color="primary" small @click="setLatLng") {{ $t('Localizar') }}
                            v-flex(xs12)
                                l-map(v-if="showMap" @click="clickOnMap" :center="center" :zoom="zoom" ref="map" style="min-height:380px;")
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="close") {{ $t('Cancelar') }}
                            v-btn(:loading="loadingBtn" color="primary" large @click="saveRegion") {{ $t('Guardar') }}
</template>

<script>

import _m from 'lodash/merge'
import config from 'src/config'

export default {
    props:{
        info: {
            type: Object,
            default(){
                return {}
            }
        },
        type: {
            type: Number|String,
            default: 0
        },
        typeid: {
            type: Number|String,
            default: ''
        },
        parentid: {
            type: Number|String,
            default: ''
        },
        regions: {
            type: Array,
            default: []
        }
    },
    data(){
        return {
            t: {
                STATE : 1,
                CITY  : 2,
                ZONE  : 3
            },
            regionSchema: {
                uuid     : '',
                name     : '',
                status   : true,
                division : '',
                tel      : '',
                iso      : '',
                lat      : '',
                lng      : ''
            },
            regionModel: {},
            regionMsj  : false,
            regionColor: '',
            regionText : '',
            regionModal: false,
            loadingBtn: false,
            updateRegion: false,

            // Mapa
            center       : null,
            zoom         : 5,
            showMap      : false,
            currentMarker: null,
            l            : null
        }
    },
    created(){
        this.regionModel = _m({}, this.regionSchema)
    },
    mounted(){
        this.l = require('leaflet')
    },
    methods: {

        async getLatLng(){

            return new Promise(async (resolve) => {

                let latLng = [4.6533326, -74.083652]
                if('geolocation' in navigator){

                    latLng = await new Promise(resolve => {
                        navigator.geolocation.getCurrentPosition((pos) => {

                            let coords = pos.coords
                            resolve([coords.latitude, coords.longitude])
                        }, () => {
                            resolve(latLng)
                        })
                    })
                }

                this.center  = this.l.latLng(latLng)
                this.showMap = true

                setTimeout(() => {

                    this.l.gridLayer.googleMutant({
                        type: 'roadmap'
                    }).addTo(this.$refs.map.mapObject)

                    this.addGeocoder()

                }, 1)
                resolve()
            })
        },

        setLatLng(){

            try{

                if(this.regionModel.lat == 0 || isNaN(this.regionModel.lat)){
                    throw "Ingrese una latitud valida"
                }

                if(this.regionModel.lat == 0 || isNaN(this.regionModel.lng)){
                    throw "Ingrese una longitud valida"
                }

                const latLng = this.l.latLng([this.regionModel.lat, this.regionModel.lng])
                this.setMarker(latLng)

            }catch(e){
                this.regionMsj   = true
                this.regionColor = 'error'
                this.regionText  = this.$t(e)
            }
        },

        clickOnMap(event){
            this.setMarker(event.latlng)
        },

        setMarker(latLng){

            if(this.currentMarker != null){
                this.currentMarker.remove()
            }

            this.currentMarker = this.l.marker(latLng).addTo(this.$refs.map.mapObject)
            this.regionModel.lat = latLng.lat
            this.regionModel.lng = latLng.lng

            this.$refs.map.mapObject.setView(latLng)
        },

        addGeocoder(){

            this.l.Control.geocoder({
                defaultMarkGeocode: false,
                placeholder       : this.$t('Buscar'),
                errorMessage      : this.$t('No se encontraron resultados'),
                geocoder          : new this.l.Control.Geocoder.Google({
                    geocodingQueryParams: {
                        key: config.mapsKey
                    }
                })
            }).on('markgeocode', (e) => {

                const latLng = e.geocode.center
                this.setMarker(latLng)

            }).addTo(this.$refs.map.mapObject)
        },

        close(){

            this.regionModal = false
            this.showMap = false
            this.regionModel = _m({}, this.regionSchema)
        },

        async open(action, title, data = this.regionSchema){

            if(this.type == this.t.STATE){
                this.zoom = 7
            }

            if(this.type == this.t.CITY){
                this.zoom = 11
            }

            if(this.type == this.t.ZONE){
                this.zoom = 13
            }

            this.titleRegion  = title
            this.updateRegion = (action == 'add' ? false: true)
            this.regionModel  = _m({}, this.regionModel, data)
            this.regionModel.status  = this.regionModel.status == 1
            
            this.loadingBtn = false
            this.regionModal  = true

            await this.getLatLng()

            // Por defecto
            if(this.regionModel.lat != 0 && this.regionModel.lng != 0){
                this.setMarker(this.l.latLng(this.regionModel.lat, this.regionModel.lng))
            }
        },
        saveRegion(){

            this.loadingBtn = true

            let url    = '/country'
            let params = _m({}, this.regionModel)
            params.status = params.status ? 1 : 0

            if(this.type == this.t.STATE){
                url = '/state'
                params.countryid = this.typeid
            }

            if(this.type == this.t.CITY){
                url = '/city'
                params.stateid = this.typeid
            }

            if(this.type == this.t.ZONE){
                url = 'zone'
                params.cityid = this.typeid
                params.parent = this.parentid
            }

            this.$api(this, (xhr) => {
                xhr.post(url, this.$qs.stringify(params)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.regionMsj  = true

                    if(data.response){
                        this.regionColor = 'success'
                        this.regionText = data.message
                        this.regionModal = false
                        this.$emit('success')
                    }else{
                        this.regionColor = 'error'
                        this.regionText = data.message
                    }

                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        }
    }
}
</script>