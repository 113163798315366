<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form(@submit.prevent="")
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                    :timeout="3000",
                    :top="true"
                    :right="true"
                    :color="regionColor"
                    v-model="regionMsj")
                    | {{ regionText }}
                    v-btn(flat dark @click="regionMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    template(v-if="type == t.STATE")
                        h1
                            | {{$t('Estados')}} {{ !Array.isArray(info) && info.name.length ? ' - '+info.name : '' }}
                        v-btn.primary(@click="toSpecialRegion(info)") {{ $t('Regiones especiales') }}
                    h1(v-else-if="type == t.CITY") 
                        | {{$t('Ciudades')}} {{ !Array.isArray(info) && info.name.length ? ' - '+info.name : '' }}
                    h1(v-else-if="type == t.ZONE") 
                        | {{$t('Zonas')}} {{ !Array.isArray(info) && info.name.length ? ' - '+info.name : '' }}
                    template(v-else)
                        h1 {{$t('Países')}}
                    v-btn.primary(v-if="type == t.STATE" depressed @click="backLink") {{$t('Volver')}}
                    v-btn.primary(v-if="type == t.CITY" depressed @click="backLink") {{$t('Volver')}}
                    v-btn.primary(v-if="type == t.ZONE" depressed @click="backLink") {{$t('Volver')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-data-table(
                            :headers='headers'
                            :items='regions'
                            :pagination.sync='pagination'
                            :rows-per-page-items="$root.rowsPageItems"
                            :rows-per-page-text="$root.rowsPageText" 
                            item-key='uuid'
                        )   
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :class="['column', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                            template(slot='items' slot-scope='props')
                                tr
                                    td(@click="toZoneRoute(props.item)") {{ props.item.name }}
                                    td(v-if="type == 3") {{ props.item.division }}
                                    td {{ $t(props.item.status == 1 ? 'Activo' : 'Inactivo') }}
                                    td.text-xs-right
                                        v-icon.cur-p.primary--text(@click="$refs.manage.open('edit', $t('Editar'), props.item)") edit
                            template(slot='pageText' slot-scope='props')
                                | {{ $t('Filas') }} {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
                    //- Opciones
                    .btns.btns-fixed.btns-bottom.btns-left
                        v-tooltip(top)
                            template(slot="activator")
                                v-btn(:loading="loadingReportBtn" @click="downloadReport" dark fab color='primary')
                                    v-icon file_download
                            span {{ $t('Descargar zonas/regiones para exportar') }}
                        v-tooltip(top)
                            template(slot="activator")
                                v-btn(@click="$refs.importZone.open(importConfig)" dark fab color='primary')
                                    v-icon file_upload
                            span {{ $t('Importar zonas') }}
                        v-btn(@click.native="$refs.manage.open('add', $t('Agregar'))" dark fab color='primary')
                            v-icon add
            manage-region(
                ref="manage" 
                @success="fetchRegion()" 
                :info="info" 
                :type="type"
                :typeid="typeid"
                :parentid="parentid"
                :regions="regions"
            )
            import-zone(ref="importZone")
</template>

<script>

    import auth from 'mixins/auth'
    import _ from 'lodash/debounce'
    import config from 'src/config'
    import ManageRegion from 'components/ManageRegion'
    import ImportZone from 'components/region/ImportZone'
    
    export default {
        components: {
            ManageRegion,
            ImportZone
        },
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Regiones')
            }
        },
        data() {
            return {
                t: {
                    STATE : 1,
                    CITY  : 2,
                    ZONE  : 3
                },
                preload: false,
                pagination: {},
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    status   : []
                },
                regions      : [],
                info: {
                    name: ''
                },
                regionMsj  : false,
                regionColor: '',
                regionText : '',
                regionModal: false,
                limit : this.$root.limit,
                offset: 0,
                loadingReportBtn: false
            }
        },
        watch: {
            filter: {
                handler(){
                    this.fetchRegion()
                },
                deep: true
            }
        },
        computed: {
            type(){
                return this.$route.query.t
            },
            typeid(){
                return this.$route.query.tid
            },
            parentid(){
                return this.$route.query.p
            },
            countryid(){
                return this.$route.query.c
            },
            stateid(){
                return this.$route.query.s
            },
            cityid(){
                return this.$route.query.c1
            },
            headers(){
                let headers = [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'status',
                        sortable: false
                    },
                    {
                        text: 'Opciones',
                        align: 'right',
                        value: 'options',
                        sortable: false
                    }
                ]

                // Zonas
                if(this.type == 3){
                    headers = [
                        {
                            text: 'Nombre',
                            align: 'left',
                            value: 'name',
                            sortable: false
                        },
                        {
                            text: 'División',
                            align: 'left',
                            value: 'division',
                            sortable: false
                        },
                        {
                            text: 'Estado',
                            align: 'left',
                            value: 'status',
                            sortable: false
                        },
                        {
                            text: 'Opciones',
                            align: 'right',
                            value: 'options',
                            sortable: false
                        }
                    ]
                }

                return headers
            },
            importConfig(){
                return {
                    type: this.type,
                    typeid: this.typeid,
                    parentid: this.parentid,
                    cityid: this.cityid
                }
            }
        },
        created(){

            this.fetchRegion()
            this.filter = Object.assign({}, this.filterSchema)
        },
        methods: {

            fetchRegion:  _(function(){
                
                this.preload = true

                let url    = '/country'
                let filter = this.filter
                let params = {
                    filter: JSON.stringify(filter)
                }

                if(this.type == this.t.STATE){
                    url = '/state'
                    params.countryid = this.typeid
                }

                if(this.type == this.t.CITY){
                    url = '/city'
                    params.stateid = this.typeid
                }

                if(this.type == this.t.ZONE){
                    url = 'zone'
                    params.cityid = this.typeid
                    params.parent = this.parentid
                }

                this.$api(this, (xhr) => {
                    xhr.get(url, { params: params }).then((r) => {

                        let data = r.data
                        this.preload = false

                        if(data.response){
                            this.regions = data.data
                            this.info    = data.info
                        }
                    }).catch(() => {})
                })
            }, 500),

            changeSort (column) {
              
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            toZoneRoute(item){

                let q = {
                    t   : this.t.STATE,
                    p   : 0,
                    tid : item.uuid
                }

                if(this.type == this.t.STATE){
                    q.t = this.t.CITY
                    q.c = this.typeid
                    q.s = item.uuid
                }

                if(this.type == this.t.CITY){
                    q.t  = this.t.ZONE
                    q.c  = this.countryid
                    q.s  = this.typeid
                    q.c1 = item.uuid
                }

                if(this.type == this.t.ZONE){
                    q.t   = this.t.ZONE
                    q.p   = item.uuid
                    q.tid = item.cityid
                    q.c   = this.countryid
                    q.s   = this.stateid
                    q.c1  = this.cityid
                }

                return this.$router.replace({ query: q })
            },

            toSpecialRegion(item){

                let q = {
                    t   : 1,
                    tid : item.uuid
                }

                return this.$router.replace({ path: '/region-s', query: q })
            },

            backLink(){

                let q = {
                    t   : '',
                    p   : 0,
                    tid : ''
                }

                if(this.type == this.t.CITY){
                    q.t   = this.t.STATE
                    q.tid = this.countryid
                }

                if(this.type == this.t.ZONE){
                    q.t   = this.t.ZONE
                    q.tid = this.cityid
                    q.c   = this.countryid
                    q.s   = this.stateid
                    q.c1  = this.cityid

                    if(this.parentid == 0){
                        q.t   = this.t.CITY
                        q.tid = this.stateid
                    }
                }

                return this.$router.replace({ query: q })
            },

            downloadReport(){

                this.loadingReportBtn =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter : JSON.stringify({
                        type: this.type,
                        typeid: this.typeid,
                        cityid: this.cityid,
                        parentid: this.parentid,
                        ...this.filter
                    })
                })

                this.$api(this, (xhr) => {
                    xhr.post('/region/export', filter).then((r) => {

                        let data = r.data
                        this.loadingReportBtn = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                        
                    }).catch(() => {})
                })
            }
        }
    }
    
</script>