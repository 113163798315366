<template lang="pug">
div
    v-snackbar(
    :timeout="30000",
    :top="true"
    :right="true"
    :color="alertColor"
    v-model="alertMsj")
        | {{ alertText }}
        v-btn(flat, dark, @click.native="alertMsj = false") {{ $t('cerrar') }}
    v-dialog(persistent lazy max-width="900px" v-model="modal")
        v-form
            v-card
                v-card-title {{ $t('Importar zonas') }}
                v-card-text
                    p {{ $t('Para importar zonas siga las instrucciones:') }}
                    ul.pl-3.pb-3
                        li {{ $t('Ingrese a la zona o región principal desde donde quiere importar las zonas') }}
                        li {{ $t('Descargue las zonas/regiones para exportar seleccionando el botón de descarga') }}
                        li {{ $t('Se mostrarán las zonas existentes, agregue la información de la nueva zona') }}
                        li {{ $t('Ingrese a la zona o región principal desde donde descargo este listado') }}
                        li {{ $t('Seleccione el botón importar y luego adjunte el archivo de importación') }}
                        li {{ $t('La importación empezara, dependiendo de la cantidad de zonas a importar puede tomar un tiempo') }}
                        li {{ $t('Recuerde que solo pude importar un nivel de zonas al tiempo') }}
                        li {{ $t('Debe importar el listado desde la sección donde descargo el listado') }}
                        li {{ $t('La importación no actualiza datos existentes, el proceso fallará si ha ocurrido algún error') }}
                    h4.mt-4 {{ $t('Nombre de los campos') }}
                    v-data-table(:headers='headers' :items="items" hide-actions)
                        template(slot='headers' slot-scope='props')
                            tr
                                th(v-for='(header, i) in props.headers' :key='i' :align="header.align")
                                    span.subheading {{ $t(header.text) }}
                        template(slot='items' slot-scope='props')
                            tr
                                td {{ props.item.id }}
                                td {{ props.item.name }}
                                td {{ props.item.required }}
                                td {{ props.item.instructions }}
                    h4.mt-4.mb-2 {{$t('Subir archivo')}}
                    input(type='file' @change="fileChangeImport")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="modal=false") {{$t('Aceptar')}}
                        v-btn(color="secondary" :loading="loadingImport" :disabled="loadingImport" small @click="startImport") {{$t('Iniciar importación')}}
    </template>
    
    <script>
    
    export default {
        data() {
            return {
                modal: false,
                loadingImport: false,
                alertMsj  : false,
                alertColor: '',
                alertText: '',
                current: {},
                headers: [
                    {
                        text: 'ID',
                        align: 'left',
                        sortable: false
                    },
                    {
                        text: 'Nombre(s)',
                        align: 'left',
                        sortable: false
                    },
                    {
                        text: 'Requerido',
                        align: 'left',
                        sortable: false
                    },
                    {
                        text: 'Instrucciones',
                        align: 'left',
                        sortable: false
                    }
                ],
                importFile: ''
            }
        },
        computed: {
            items(){
                return [
                    {
                        id      : 'name',
                        name    : this.$t('Nombre(s)'),
                        required: this.$t('Si'),
                        instructions: this.$t('Nombre de la zona')
                    },
                    {
                        id      : 'division',
                        name    : this.$t('División'),
                        required: this.$t('Si'),
                        instructions: this.$t('División geográfica (no es requerido si ya existe)')
                    },
                    {
                        id      : 'lat',
                        name    : this.$t('Latitud'),
                        required: this.$t('No'),
                        instructions: ''
                    },
                    {
                        id      : 'lng',
                        name    : this.$t('Longitud'),
                        required: this.$t('No'),
                        instructions: ''
                    }
                ]
            },
        },
        methods: {
            async open(item) {
                this.current = this._.cloneDeep(item)
                this.loadingImport = false
                this.importFile = ''
                this.modal  = true
            },
            fileChangeImport(e) {

                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return

                this.importFile = files[0]
            },
            startImport(){

                let post = new FormData()
                
                post.append('upload', this.importFile)
                post.append('data', JSON.stringify(this.current))

                this.loadingImport = true
                this.$api(this, (xhr) => {
                    xhr.post('/region/import', post).then((r) => {

                        let data = r.data
                        this.loadingImport = false
                        this.alertMsj = true

                        if(data.response){
                            this.alertColor = 'success'
                            this.alertText = data.message
                            this.modal = false
                        }else{
                            this.alertColor = 'error'
                            this.alertText = data.message
                        }
                    }).catch(() => {
                        this.loadingImport = false
                    })
                })
            },
        }
    }
</script>